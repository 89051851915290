var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card-group",
        { staticClass: "mb-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "col-md-8 p-0 mr-2",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Filtros ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1 mt-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-md-0 mb-1", attrs: { sm: "6" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.gestao
                            },
                            model: {
                              value: _vm.filtro.fields.gestao,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "gestao", $$v)
                              },
                              expression: "filtro.fields.gestao"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1", attrs: { sm: "6" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options:
                                _vm.filtro.listas.centresFiltradosPorGestao
                            },
                            model: {
                              value: _vm.filtro.fields.centres,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "centres", $$v)
                              },
                              expression: "filtro.fields.centres"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pr-md-0", attrs: { sm: "6" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.tipoinfor,
                              disabled: _vm.filtro.fields.exibicao === "Tabelas"
                            },
                            model: {
                              value: _vm.filtro.fields.tipoinfor,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "tipoinfor", $$v)
                              },
                              expression: "filtro.fields.tipoinfor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-card",
            {
              staticClass: "col-md-4 p-0",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Layout ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-md-block", attrs: { sm: "12" } },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  model: {
                                    value: _vm.filtro.fields.exibicao,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "exibicao",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.exibicao"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: {
                                        value: "Graficos",
                                        disabled: [2, 4].includes(
                                          _vm.filtro.fields.agrupamento
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.loaded = true
                                        }
                                      }
                                    },
                                    [_vm._v("Gráficos")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Tabelas" }
                                    },
                                    [_vm._v("Tabelas")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filterAgrupamento
                            },
                            on: { change: _vm.search },
                            model: {
                              value: _vm.filtro.fields.agrupamento,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "agrupamento", $$v)
                              },
                              expression: "filtro.fields.agrupamento"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.filtro.fields.exibicao === "Tabelas"
        ? _c(
            "div",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-row",
                { key: index },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "12", lg: "12" } },
                    [
                      _c("c-table", {
                        attrs: {
                          "table-data": item.records.tabela,
                          fields: _vm.fields,
                          striped: "",
                          small: "",
                          caption: item.name,
                          "per-page": 50
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.filtro.fields.exibicao === "Graficos"
        ? _c(
            "div",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-card",
                { key: index },
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "d-flex-inline mr-auto" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _c("div", { staticClass: "d-flex-inline ml-auto" }, [
                          _c("i", {
                            staticClass:
                              "fa fa-download text-primary cursor-pointer",
                            attrs: { title: "Exportar" },
                            on: {
                              click: function($event) {
                                return _vm.export2image(
                                  "chart-bar-01",
                                  "" + item.name
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _vm.loaded &&
                  _vm.displayChart(
                    item,
                    _vm.filtro.fields.tipoinfor === 1 ? "Vendas" : "Pecas"
                  )
                    ? _c("horizontal-bar", {
                        attrs: {
                          chartdata:
                            item.charts[
                              _vm.filtro.fields.tipoinfor === 1
                                ? "Vendas"
                                : "Pecas"
                            ],
                          height:
                            (item.charts[
                              _vm.filtro.fields.tipoinfor === 1
                                ? "Vendas"
                                : "Pecas"
                            ].datasets[0].data.length +
                              15) *
                            10,
                          displayLegend: true,
                          moneyFormat:
                            _vm.filtro.fields.tipoinfor === 1 ? true : false,
                          chartId: "chart-bar-01"
                        }
                      })
                    : _vm._e(),
                  !_vm.displayChart(
                    item,
                    _vm.filtro.fields.tipoinfor === 1 ? "Vendas" : "Pecas"
                  )
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-secondary text-center mb-0"
                        },
                        [
                          _vm._v(
                            "\n        Nenhum resultado encontrado.\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }