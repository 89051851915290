<template>
  <div class="animated fadeIn">
    <b-card-group class="mb-4">
      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-8 p-0 mr-2">
        <div slot="header">
          <strong> Filtros </strong>
        </div>
        <div class="p-1 mt-2">
          <b-row>
            <b-col sm="6" class="pr-md-0 mb-1">
              <b-form-select :plain="true" v-model="filtro.fields.gestao" :options="filtro.listas.gestao"></b-form-select>
            </b-col>
            <b-col sm="6" class="mb-1">
              <b-form-select :plain="true" v-model="filtro.fields.centres" :options="filtro.listas.centresFiltradosPorGestao"></b-form-select>
            </b-col>
            <b-col sm="6" class="pr-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.tipoinfor" :options="filtro.listas.tipoinfor" :disabled="filtro.fields.exibicao === 'Tabelas'"></b-form-select>
            </b-col>
            <b-col sm="1">
              <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-4 p-0">
        <div slot="header">
          <strong> Layout </strong>
        </div>
        <div class="p-1">
          <b-row class="mb-1">
            <b-col sm="12" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.exibicao" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Graficos" @click="loaded = true" :disabled="[2, 4].includes(filtro.fields.agrupamento)">Gráficos</b-form-radio>
                  <b-form-radio class="mx-0" value="Tabelas">Tabelas</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col sm="12">
              <b-form-select :plain="true" v-model="filtro.fields.agrupamento" :options="filterAgrupamento" @change="search"></b-form-select>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>

    <div v-if="filtro.fields.exibicao === 'Tabelas'">
      <b-row v-for="(item, index) in items" :key="index">
        <b-col cols="12" sm="12" lg="12">
          <c-table :table-data="item.records.tabela" :fields="fields" striped small :caption="item.name" :per-page="50"></c-table>
        </b-col>
      </b-row>
    </div>

    <div v-if="filtro.fields.exibicao === 'Graficos'">
      <b-card v-for="(item, index) in items" :key="index">
        <div slot="header" class="font-weight-bold">
          <div class="d-flex">
            <div class="d-flex-inline mr-auto">{{ item.name }}</div>
            <div class="d-flex-inline ml-auto">
              <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-bar-01', `${item.name}`)" title="Exportar"></i>
            </div>
          </div>
        </div>
        <horizontal-bar
          :chartdata="item.charts[filtro.fields.tipoinfor === 1 ? 'Vendas' : 'Pecas']"
          :height="(item.charts[filtro.fields.tipoinfor === 1 ? 'Vendas' : 'Pecas'].datasets[0].data.length + 15) * 10"
          :displayLegend="true"
          :moneyFormat="filtro.fields.tipoinfor === 1 ? true : false"
          chartId="chart-bar-01"
          v-if="loaded && displayChart(item, filtro.fields.tipoinfor === 1 ? 'Vendas' : 'Pecas')"
        />
        <div class="alert alert-secondary text-center mb-0" v-if="!displayChart(item, filtro.fields.tipoinfor === 1 ? 'Vendas' : 'Pecas')">
          Nenhum resultado encontrado.
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import API from '@/core/api';
import cTable from '@/core/components/Table.vue'
import HorizontalBar from '@/core/components/charts/HorizontalBar';
import Pie from '@/core/components/charts/Pie';
import session from '@/shared/session';
import lojasPorGestaoUniMixin from '@/core/mixins/lojasPorGestaoUni.mixin.js';

export default {
  name: 'Consignacoes',
  components: {
    cTable,
    HorizontalBar,
    Pie
  },
  mixins:[
    lojasPorGestaoUniMixin
  ],
  data: () => {
    return {
      loaded: false,
      filtro: {
        listas: {
          gestao: session.get('gestoes'),
          centres: session.get('lojas'),
          tipoinfor: [{value: 1, text: 'R$'}, {value: 2, text: 'Peças'}],
          agrupamentos: [{value: 1, text: 'Gestão/Loja'}, {value: 2, text: 'Gestão/Cliente'}, {value: 3, text: 'Grupo Empresarial/Loja'}, {value: 4, text: 'Grupo Empresarial/Cliente'}, {value: 5, text: 'Grupo Empresarial/Gestão'}],
          centresFiltradosPorGestao: []
        },
        fields: {
          gestao: 0,
          centres: 0,
          tipoinfor: 1,
          exibicao: 'Graficos',
          agrupamento: 1
        }
      },
      items: [],
      fields: [
        {key: 'descricao', label: 'Descrição', sortable: true},
        {key: 'primeira_saida', label: '1º Saída', sortable: true, thClass: 'text-left text-nowrap', formatter: value => moment(value).format('DD/MM/YYYY')},
        {key: 'dias_aberto', label: 'Dias em aberto', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR')},
        {key: 'fd_val_sai', label: 'Saídas R$', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'fd_qtd_sai', label: 'Saídas Peças', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR')},
        {key: 'fd_val_ent', label: 'Entradas R$', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'fd_qtd_ent', label: 'Entradas Peças', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR')},
        {key: 'fd_val_sld', label: 'Pendente R$', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'fd_qtd_sld', label: 'Pendente Peças', sortable: true, thClass: 'text-right text-nowrap', tdClass: 'text-right', formatter: value => Number(value).toLocaleString('pt-BR')}
      ],
      charts: {}
    }
  },
  computed: {
    filterAgrupamento: function() {
      const { exibicao } = this.filtro.fields
      return exibicao === 'Tabelas'
        ? this.filtro.listas.agrupamentos
        : this.filtro.listas.agrupamentos.filter(a => ![2, 4].includes(a.value))
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.loaded = false

          const consignacoesAPI = new API('api/consignacoes');
          const {data} = await consignacoesAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.items = data;
          
          const { centres, agrupamento } = this.filtro.fields
          if (centres === 0 || agrupamento === 2) {
            this.aplicarAgrupamento();
          }

          this.loadCharts();
        }
      } catch (e) {
        console.error(e)
      }
    },
    aplicarAgrupamento() {
      const { agrupamento } = this.filtro.fields

      if ([1, 2].includes(agrupamento)) {
        let group = []
        let gestoes = this.filtro.listas.gestao.filter(g => g.value && g.value !== 0)
        let _group = _.groupBy(this.items[0].records.tabela, 'gestao')
        gestoes.forEach(g => {
          if (_group[g.value]) {
            group.push({
              name: _group[g.value][0].gestao_desc,
              records: {
                tabela: [ ..._group[g.value] ],
                grafico: [ ..._group[g.value] ],
              }
              //grafico: this.items[0].grafico.filter(graf => Number(graf.gestao) === g.value)
            })
          }
        })

        if (group.length > 0) {
          this.items = group
        }
      } else if ([3, 4].includes(agrupamento)) {
        this.items[0].name = 'Grupo Empresarial'
      } else if ([5].includes(agrupamento)) {
        let grouped = []
        let groupBy = Object.values(_.groupBy(this.items[0].records.tabela, 'gestao'))
        groupBy.forEach(gestao => {
          const obj = gestao.reduce((acc, cur) => {
            return {
              cod: cur.gestao,
              descricao: cur.gestao_desc,
              gestao: cur.gestao,
              gestao_desc: cur.gestao_desc,
              grupo: cur.grupo,
              primeira_saida: cur.primeira_saida,
              dias_aberto: cur.dias_aberto,
              fd_val_sai: parseFloat(acc.fd_val_sai || 0.00) + parseFloat(cur.fd_val_sai),
              fd_qtd_sai: Number(acc.fd_qtd_sai || 0) + Number(cur.fd_qtd_sai),
              fd_val_ent: parseFloat(acc.fd_val_ent || 0.00) + parseFloat(cur.fd_val_ent),
              fd_qtd_ent: Number(acc.fd_qtd_ent || 0) + Number(cur.fd_qtd_ent),
              fd_val_sld: parseFloat(acc.fd_val_sld || 0.00) + parseFloat(cur.fd_val_sld),
              fd_qtd_sld: Number(acc.fd_qtd_sld || 0) + Number(cur.fd_qtd_sld),
            }
          }, {})

          grouped.push(obj)
        })

        if (grouped.length > 0) {
          this.items[0] = {
            name: 'Grupo Empresarial',
            records: {
              tabela: grouped,
              grafico: grouped
            }
          }
        }
      }
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    loadCharts() {
      this.items.forEach(items => {
        const labels = items.records.grafico.map(r => r.descricao).join().split(',');

        items.charts = {
          Vendas: {
            labels: labels,
            datasets: [
              {
                label: 'Saídas',
                backgroundColor: '#f76a6a',
                data: items.records.grafico.map(r => r.fd_val_sai).join().split(',')
              },
              {
                label: 'Entradas',
                backgroundColor: '#ffc106',
                data: items.records.grafico.map(r => r.fd_val_ent).join().split(',')
              },
              {
                label: 'Pendentes',
                backgroundColor: '#20c997',
                data: items.records.grafico.map(r => r.fd_val_sld).join().split(',')
              }
            ]
          },
          Pecas: {
            labels: labels,
            datasets: [
              {
                label: 'Saídas',
                backgroundColor: '#f76a6a',
                data: items.records.grafico.map(r => r.fd_qtd_sai).join().split(',')
              },
              {
                label: 'Entradas',
                backgroundColor: '#ffc106',
                data: items.records.grafico.map(r => r.fd_qtd_ent).join().split(',')
              },
              {
                label: 'Pendentes',
                backgroundColor: '#20c997',
                data: items.records.grafico.map(r => r.fd_qtd_sld).join().split(',')
              }
            ]
          }
        }
      })

      this.loaded = true
    },
    displayChart(item, tipo) {
      if (item.charts[tipo].datasets) {
        const data = item.charts[tipo].datasets[0].data
        if (data.length === 0 || ['', '0'].includes(data[0])) {
          return false
        }
      }
      return true
    },
    export2image: function (id, name) {
      // Set White Background
      let chart = document.getElementById(id)
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name || 'Grafico'
      link.href = canvas
      link.click()
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        centres,
        formaVenda
      } = filters;

      const lojas = this.filtro.listas.centres.map(c => c.value).join(',')

      filter.centres = centres === 0 ? lojas : centres;
      filter.formaVenda = formaVenda !== 0 ? [formaVenda] : 0;
      if (typeof filter.centres === 'number') {
        filter.listaPorCliente = true
      }
      return filter;
    },
    dynamicColors(count) {
      let result = [ ...this.$store.state.colors ];
      for (let i = 0; i < count; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);

        result.push("rgb(" + r + "," + g + "," + b + ",.7)")
      }
      return result;
    }
  },
  created() {
    this.search();
  }
}
</script>
